import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Format } from './../domaine/format';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormatService {
  constructor(private http: HttpClient) { }

  all(): Observable<Format[]>{
    return this.http.get<Format[]>(`${ environment.apiUrl }/format/`).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }


  add(criticite: Format): Observable<Format>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Format>(`${ environment.apiUrl }/format/`, criticite, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  update(criticite: Format): Observable<Format>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<Format>(`${ environment.apiUrl }/format/` + criticite.id, criticite, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  delete(id: number): Observable<Format>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<Format>(`${ environment.apiUrl }/format/` + id).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  handleError(err: any){
    return throwError(err);
  }
}
