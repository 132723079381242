import { AuthService } from './service/auth.service';
import { TockenService } from './service/tocken.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree , Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private tokenService: TockenService , private authService: AuthService , private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!this.tokenService.loggedIn()){
          this.tokenService.removeTocken();
          this.authService.changeAuthStatus(false);
          this.router.navigateByUrl('/login');
          return false;
      }
      return true;
  }
}
