import { Component, OnInit } from '@angular/core';
import { Correspondant } from './../../../../domaine/correspondant';
import { Compagnie } from './../../../../domaine/compagnie';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CorrespondantService } from 'src/app/numerotation/service/correspondant.service';
import { CompagnieService } from './../../../../service/compagnie.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-add-correspondant',
  templateUrl: './add-correspondant.component.html',
  styleUrls: ['./add-correspondant.component.scss']
})
export class AddCorrespondantComponent implements OnInit {

  correspondant: Correspondant;
  compagnies: Compagnie[];
 

  correspondantForm: FormGroup;

  categories: any[] = [{name: 'Oui'}, {name: 'Non'}];

  constructor(
    private correspondantService: CorrespondantService,
    private compagnieService: CompagnieService,
    private route: ActivatedRoute ,
    private router: Router) {   
                
                
    this.correspondant = new Correspondant();
    
    
    const compagnies = this.compagnieService.all();

    this.correspondantForm = new FormGroup({
      civilite : new FormControl(null, Validators.required),
      nom : new FormControl(null, Validators.required),
      prenom : new FormControl(null, Validators.required),
      compagnie : new FormControl(null, Validators.required),
      email : new FormControl(null),
      telephone : new FormControl(null),
      actif : new FormControl({name:'Non'} , Validators.required )
    });

    forkJoin([ compagnies ]).subscribe(
      result => {        
        this.compagnies = result[0];
        
        this.correspondantForm.setValue({
          civilite : this.correspondant.civilite,
          nom : this.correspondant.nom,
          prenom : this.correspondant.prenom,     
          email : this.correspondant.email,
          telephone : this.correspondant.telephone
        });

      }
    );
   

  }

  ngOnInit(): void {
    
  }

  add(){
    this.correspondant.civilite = this.correspondantForm.get('civilite').value;
    this.correspondant.nom = this.correspondantForm.get('nom').value;
    this.correspondant.prenom = this.correspondantForm.get('prenom').value;    
    this.correspondant.email = this.correspondantForm.get('email').value;
    this.correspondant.telephone = this.correspondantForm.get('telephone').value;
    this.correspondant.compagnie = this.correspondantForm.get('compagnie').value;
    this.correspondant.actif = (this.correspondantForm.get('actif').value.name == 'Oui')? true: false;   
    this.correspondantService.add(this.correspondant ).subscribe(
      data => {
        this.router.navigate(['/numerotation/administration/correspondant']);
      },
      err => {
        alert(err.error.message);
      }
    );
  }

  

}
