import { Component, OnInit } from '@angular/core';
import {  } from '@angular/core';
import { Compagnie } from '../../domaine/compagnie';
import { CompagnieService } from '../../service/compagnie.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  compagnies: Compagnie[];

  constructor(private compagnieService: CompagnieService) { }

  ngOnInit(): void {
    this.compagnieService.all().subscribe(
      data=>{
        this.compagnies = data;
      },
      err => {
        alert(err.error.Message);
      }
    );
  }

}
