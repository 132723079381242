
import { CurrentUser } from './authentication/domaine/current-user';
import { Router } from '@angular/router';
import { TockenService } from './authentication/service/tocken.service';
import { AuthService } from './authentication/service/auth.service';
import { Component, OnInit } from '@angular/core';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import { timer } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-topbar',
    template: `
    <ng-container>
        <div class="topbar clearfix" >
            <div class="topbar-left">
                <a routerLink="/">
                    <img src="assets/layout/images/LogoFmsarBlanc.png" class="topbar-logo" routerLink="/" />
                </a>
            </div>
            <div class="topbar-right">
                <a id="menu-button" href="#" (click)="appMain.onMenuButtonClick($event)"
                   [ngClass]="{'menu-button-rotate': appMain.rotateMenuButton}">
                    <i class="pi pi-angle-left"></i>
               </a>

                <a id="topbar-menu-button" href="#" (click)="appMain.onTopbarMenuButtonClick($event)">
                    <i class="pi pi-bars"></i>
                </a>

                <ul class="topbar-items fadeInDown" [ngClass]="{'topbar-items-visible': appMain.topbarMenuActive}">
                    <li #profile class="profile-item"[ngClass]="{'active-top-menu':appMain.activeTopbarItem === profile}">

                        <a href="#" (click)="appMain.onTopbarItemClick($event,profile)">
                            <img class="profile-image" src="assets/layout/images/avatar.png">
                            <span class="topbar-item-name" > {{ nom }}</span> <br>  
                             <span class="topbar-item-name" > {{ societe }}</span>                         
                        </a>
                        <a href="#" (click)="appMain.onTopbarItemClick($event,profile)">                            
                           
                        </a>
                        <ul class="layout-menu" [ngClass]="{'fadeInDown':!appMain.isMobile()}">
                            <li role="menuitem">
                                <a routerLink="/logout">
                                <i class="pi pi-fw pi-sign-out"></i>
                                <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        </ng-container>
    `
})
export class AppTopBarComponent implements OnInit {


    nom: String;
    societe: string;
    constructor(
        public app: AppComponent,
        public appMain: AppMainComponent ,
        private authService: AuthService,
        private tockenService: TockenService,
        private router: Router) {
           
        }


    ngOnInit(): void {        
        this.nom = localStorage.getItem('nom');
        this.societe = localStorage.getItem('societe');      
    }
    logout(){
        this.tockenService.removeTocken();
        this.authService.changeAuthStatus(false);       
        this.router.navigateByUrl('/login');
    }


    handleError(err){
        alert(err.error.message);
        switch (err.status){          
          case 404 : {            
            this.router.navigateByUrl('/');
            break;
          }          
        }
      }

}
