import { Autorisation } from './utilisateur/domaine/autorisation';
import { error } from 'selenium-webdriver';
import { CurrentUser } from './authentication/domaine/current-user';
import { Router } from '@angular/router';
import {Component, OnInit} from '@angular/core';
import { AuthService } from './authentication/service/auth.service';
import { timer } from 'rxjs';

@Component({
    selector: 'app-menu',
    template: `
    <div class="p-mr-1 p-mt-3 p-ml-5 p-mb-1" *ngIf="currentUser?.roles">
    <button *ngIf="currentUser.roles.indexOf('ROLE_ADD_TICKET') > -1"  pButton pRipple type="button" icon="pi pi-plus" label="Nouveau ticket" class="p-button-success p-button-outlined"  (click)="newTicket()">
    </button>
    </div>
    <ul class="layout-menu layout-main-menu clearfix">
        <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true" ></li>
    </ul>
    `
})
export class AppMenuComponent implements OnInit {

    model: any[] = new Array();
    currentUser: CurrentUser;

    constructor(private router: Router , private authService: AuthService){

    }

    ngOnInit() {
        this.model = [
            

           
            // Numerotation
            {
                label: 'Numerotation', icon: 'pi pi-fw pi-desktop',
                autorisation: 'ROLE_NUMEROTATION_CONSULT',
                items: [
                    {
                        label: 'Numerotation',
                        routerLink: ['/numerotation'],
                        icon: 'pi pi-align-justify',
                        autorisation: 'ROLE_NUMEROTATION_CONSULT',
                    }
                ]

            },

            // Administration
            {
                        label: 'Administrations', icon: 'pi pi-fw pi-users',
                        autorisation: 'ROLE_ADMIN',
                        items: [
                            {
                                label: 'Utilisateurs',
                                icon: 'pi pi-fw pi-users',
                                autorisation: 'ROLE_ADMIN',
                                routerLink: ['/administration/utilisateur'],
                                /* items: [
                                    {
                                        label: 'Utilisateur',
                                        routerLink: ['/administration/utilisateur/user'],
                                        // icon: 'pi pi-id-card',
                                        autorisation: 'ROLE_ADMIN',
                                    },
                                    {
                                        label: 'Socièté',
                                        routerLink: ['/administration/utilisateur/societe'],
                                        // icon: 'pi pi-id-card',
                                        autorisation: 'ROLE_ADMIN',
                                    },
                                    {
                                        label: 'Autorisations',
                                        routerLink: ['/administration/utilisateur/autorisation'],
                                        // icon: 'pi pi-id-card',
                                        autorisation: 'ROLE_ADMIN',
                                    },
                                    {
                                        label: 'Service',
                                        routerLink: ['/administration/utilisateur/service'],
                                        // icon: 'pi pi-id-card',
                                        autorisation: 'ROLE_ADMIN',
                                    },
                                 ]*/
                            },
                            {
                                label: 'Numerotation',
                                icon: 'pi pi-fw pi-align-justify',
                                routerLink: ['/numerotation/administration'],
                                autorisation: 'ROLE_ADMIN'
                            }
                        ]
            },
        ];

        this.authService.current.subscribe(
            currentUser => {
                this.currentUser = currentUser;
            },
            err => {
                alert(err.error.message);
            },
            () => {
            }
        );
    }

    
}
