import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Personne } from './../domaine/personne';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PersonneService {

  constructor(private http: HttpClient) { }

  all(): Observable<Personne[]>{
    return this.http.get<Personne[]>(`${ environment.apiUrl }/personne/`).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }


  add(personne: Personne): Observable<Personne>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Personne>(`${ environment.apiUrl }/personne/`, personne, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  update(personne: Personne , slug: string): Observable<Personne>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<Personne>(`${ environment.apiUrl }/personne/` + slug, personne, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
    )
    );
  }

  delete(slug: string): Observable<Personne>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<Personne>(`${ environment.apiUrl }/personne/` + slug).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  find(slug: string): Observable<Personne>{
    return this.http.get<Personne>(`${ environment.apiUrl }/personne/` + slug).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
    )
    );
  }

  handleError(err: any){
    return throwError(err);
  }
}
