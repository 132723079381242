import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  products: any;

    sortOptions: any;

    sortOrder: number;

    sortField: string;

    constructor() { }

    ngOnInit() {
        this.products = [
		{
			
			"name": "UTILISATEUR",
			"description": "Paramétrage et administration des utilisateurs",
			"image": "bamboo-watch.jpg"	,
      "link" : "/administration/utilisateur/user"
		},
		{
			
			"name": "SOCIETE",
			"description": "Paramétrage et administration des sociétés",
			"image": "black-watch.jpg",
      "link" :    "/administration/utilisateur/societe"
		},
		{
			
			"name": "AUTORISATION",
			"description": "Paramétrage et administration des  Autorisations",
			"image": "blue-band.jpg",
      "link" : "/administration/utilisateur/autorisation"
			
		},
		{
			
			"name": "SERVICE",
			"description": "Paramétrage et administration des Services",
			"image": "blue-t-shirt.jpg",
      "link" : "/administration/utilisateur/service" 
			
		}
		
	]


       

       
    }
  }
