import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataTocken } from '../domaine/data-tocken';
import { CurrentUser } from './../domaine/current-user';

@Injectable({
  providedIn: 'root'
})
export class TockenService {

  libelleTocken = 'tocken';
  libelleUser = 'nom';
  libelleRole = 'roles';
  libelleSociete = 'societe';

  currentUser: CurrentUser

  constructor() {
    this.currentUser = new CurrentUser();
  }

  set(dataTocken: DataTocken){
    localStorage.setItem(this.libelleTocken, dataTocken.token);
    localStorage.setItem(this.libelleUser, dataTocken.nom +' '+ dataTocken.prenom);
    localStorage.setItem(this.libelleRole, dataTocken.roles.toString());
    localStorage.setItem(this.libelleSociete, dataTocken.societe);
    this.currentUser.nom = dataTocken.nom;
    this.currentUser.prenom = dataTocken.prenom;
  }

  handle(dataTocken: DataTocken){
    this.set(dataTocken);
  }

  getTocken(){
    return localStorage.getItem(this.libelleTocken);
  }

  removeTocken(){
    localStorage.removeItem(this.libelleTocken);
    localStorage.removeItem(this.libelleRole);
    localStorage.removeItem(this.libelleSociete);
    localStorage.removeItem(this.libelleUser);
  }

  decode(payload: string){
      return JSON.parse(atob(payload));
  }


  getPayload(tocken: string){
    const payload = tocken.split('.')[1];
    return this.decode(payload);
  }

  isValid(){
    const tocken = this.getTocken();
    if (tocken){
      const payload = this.getPayload(tocken);
      if (payload){
        return true;
      }

    }
    return false;
  }

  getInfo(){
    const tocken = this.getTocken();
    if (tocken){
      const payload = this.getPayload(tocken);
      return payload ? payload : null;
    }
    return null;
  }

  loggedIn(){
    return this.isValid();
  }


  getCurrentUser(){
    return this.currentUser;
  }


}
