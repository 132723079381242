import { TockenService } from './tocken.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/authentication/service/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private tockenService: TockenService , private router: Router,
  
    private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.url;
    if (!url.includes('/users/signin')){
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${ this.tockenService.getTocken() }`
        }
      });
    }
    return next.handle(request).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );    

  } 

    handleError(err: any){
      switch (err.status){
        case 403 : {
          alert(err.error.message);
          if (err.error.message === 'JWT Token has expired'){
            this.tockenService.removeTocken();
            this.authService.changeAuthStatus(false);
            this.authService.setCurrentUser(null);
            this.router.navigateByUrl('/login');
          }else{
            this.router.navigateByUrl('/');
          }
          break;
        }
        case 500 : {
          alert(err.error.message);        
          
        }
        default :{
          return throwError(err);
        }
      }
      
    }
}
