import { Autorisation } from 'src/app/utilisateur/domaine/autorisation';
import { Message } from 'primeng/api';
import { UserEdit } from './../../../domaine/user-edit';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilisateurService } from './../../../service/utilisateur.service';
import { SocieteService } from './../../../service/societe.service';
import { ServiceService } from './../../../service/service.service';
import { ProfilService } from './../../../service/profil.service';
import { AutorisationService } from './../../../service/autorisation.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserAdd } from './../../../domaine/user-add';
import { Profil } from './../../../domaine/profil';
import { Service } from './../../../domaine/service';
import { Societe } from './../../../domaine/societe';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/utilisateur/domaine/user';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  societes: Societe[];
  services: Service[];
  userEdit: UserEdit;
  user: User;
  title: string;

  userForm: FormGroup;

  roles: Autorisation[];
  selectedRoles: Autorisation[];

  constructor(private autorisationService: AutorisationService ,
              private profilService: ProfilService,
              private serviceService: ServiceService,
              private societeService: SocieteService,
              private utilisateurService: UtilisateurService,
              private route: ActivatedRoute ,
              private router: Router) {}

  ngOnInit(): void {
    this.userEdit = new UserEdit();
    const slug = this.route.snapshot.paramMap.get('slug');
    const services = this.serviceService.all();
    const societes = this.societeService.all();
    const user = this.utilisateurService.find(slug);
    const roles = this.autorisationService.all();

    this.userForm = new FormGroup({
      ctrEmail : new FormControl(null, Validators.required),
      ctrNom : new FormControl(null, Validators.required),
      ctrPrenom : new FormControl(null, Validators.required),
      ctrService : new FormControl(null, Validators.required),
      ctrSociete : new FormControl(null, Validators.required),
      ctrTelephoneFixe : new FormControl(null),
      ctrTelephoneMobile : new FormControl(null),
      ctrActive : new FormControl(null , Validators.required )
    });


    forkJoin([ services, societes , roles , user ]).subscribe(
      result => {
        this.services = result[0];
        this.societes = result[1];
        this.roles = result[2];
        this.user = result[3];

        this.title = 'Editer utilisateur ' ;

        this.userForm.setValue({
          ctrEmail : this.user.email,
          ctrNom : this.user.nom,
          ctrPrenom : this.user.prenom,
          ctrService : this.user.service,
          ctrSociete : this.user.societe,
          ctrTelephoneFixe : this.user.telephoneFixe,
          ctrTelephoneMobile : this.user.telephonePortable,
          ctrActive :  this.user.active
        });

        this.selectedRoles = this.user.roles;
      }
    );


  }

  update(){
    this.userEdit.email = this.userForm.get('ctrEmail').value;
    this.userEdit.nom = this.userForm.get('ctrNom').value;
    this.userEdit.prenom = this.userForm.get('ctrPrenom').value;
    this.userEdit.societe = this.userForm.get('ctrSociete').value.slug;
    this.userEdit.service = this.userForm.get('ctrService').value.id;
    this.userEdit.telephoneFixe = this.userForm.get('ctrTelephoneFixe').value;
    this.userEdit.telephonePortable = this.userForm.get('ctrTelephoneMobile').value;
    this.userEdit.active = this.userForm.get('ctrActive').value;
    this.userEdit.roles = this.selectedRoles;

    console.log(this.userEdit);

    this.utilisateurService.update(this.userEdit , this.user.slug).subscribe(
      data => {
        this.router.navigate(['/administration/utilisateur/user']);
      },
      err => {
        alert(err.error.message);
      }
    );
  }

  delete(){
    this.utilisateurService.delete(this.user.slug).subscribe(
      data => {
        this.router.navigate(['/administration/utilisateur/user']);
      },
      err => {
        alert(err.error.Message);
      }
    );
  }

}
