import { Component, OnInit } from '@angular/core';
import { Compagnie } from 'src/app/compagnie/domaine/compagnie';
import { Usage } from 'src/app/usage/domaine/usage';
import { Correspondant } from '../../domaine/correspondant';
import { Demande } from '../../domaine/demande';
import { Format } from '../../domaine/format';
import { Signature } from '../../domaine/signature';
import { TypeDemande } from '../../domaine/type-demande';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Message, PrimeNGConfig, MessageService } from 'primeng/api';
import { UsageService } from 'src/app/usage/service/usage.service';
import { FormatService } from '../../service/format.service';
import { CorrespondantService } from '../../service/correspondant.service';
import { SignatureService } from '../../service/signature.service';
import { TypeDemandeService } from '../../service/type-demande.service';
import { DemandeService } from '../../service/demande.service';
import { CompagnieService } from 'src/app/compagnie/service/compagnie.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TockenService } from 'src/app/authentication/service/tocken.service';
import { AuthService } from 'src/app/authentication/service/auth.service';
import { forkJoin } from 'rxjs';
import { DetailDemande } from '../../domaine/detail-demande';
import { PieceJointeService } from './../../service/piece-jointe.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  compagnies: Compagnie[];
  selectedCompagnie: Compagnie;

  usages: Usage[];

  formats: Format[];

  uploadedFiles: any[] = [];

  correspondants: Correspondant[];
  allCorrespondant: Correspondant[];
  selectedCorrespondant: Correspondant;

  signatures: Signature[];

  typeDemandes: TypeDemande[];

  demande: Demande = new Demande();

  titre: string;

  detailValid: boolean ;

  displayDialogDetailDemande = false;

  isEdit: boolean;

  demandeForm: FormGroup;
  detailDemandeForm: FormGroup;

  msgs1: Message[];

  currentRoute: string;
  dateExp = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;

  constructor(
    private usageService: UsageService ,
    private formatService: FormatService ,
    private correspondantService: CorrespondantService,
    private signatureService: SignatureService,
    private typeDemandeService: TypeDemandeService,
    private demandeService: DemandeService,
    private formBuilder: FormBuilder,
    private pieceJointeService: PieceJointeService,
    private compagnieService: CompagnieService,
    private route: ActivatedRoute ,
    private router: Router,
   
    private tockenService: TockenService ,
    private authService: AuthService) {
      this.isEdit = false;
    }

  ngOnInit(): void {
    const slug = this.route.snapshot.paramMap.get('slug'); 
    this.demandeForm = new FormGroup({
      ctrCompagnie: new FormControl(null, Validators.required),
      ctrCorrespondant : new FormControl(null, Validators.required),
      ctrTypeDemande : new FormControl(null, Validators.required),
      ctrSignature : new FormControl(null, Validators.required),
      ctrDateDemande : new FormControl(new Date()),
      ctrDateReponse : new FormControl(new Date(), Validators.required)
    });

    this.detailDemandeForm = new FormGroup({
      ctrUsage: new FormControl(null, Validators.required),
      ctrFormat: new FormControl(null, Validators.required),
      ctrQuantite: new FormControl(null, Validators.required)
    });

    forkJoin([
      this.compagnieService.all() ,
      this.correspondantService.all(),
      this.typeDemandeService.all(),
      this.usageService.all(),
      this.formatService.all() ,
      this.signatureService.all(),
      this.demandeService.find(slug)]).subscribe(
      result => {
        this.compagnies =  result['0'];
        this.allCorrespondant = result['1'];
        this.typeDemandes = result['2'];
        this.usages = result['3'];
        this.formats = result['4'];
        this.signatures = result['5'];
        this.demande = result['6'];

        console.log(this.demande);

        this.correspondants = [];
        for (const correspondant of this.allCorrespondant){
            if (correspondant.compagnie.id === this.demande.correspondant.compagnie.id){
                this.correspondants.push(correspondant);
            }
        }
        this.titre = 'Editer demande : ' + this.demande.id;
        this.detailValid = true;
        this.demandeForm.setValue({
              ctrCompagnie: this.demande.correspondant.compagnie,
              ctrCorrespondant: this.demande.correspondant,
              ctrTypeDemande: this.demande.typeDemande,
              ctrSignature: this.demande.signature,
              ctrDateDemande: new Date(this.demande.dateDemande),
              ctrDateReponse: new Date(this.demande.dateReponse)
        });
      },
      err => {
        this.handleError(err);
      }
    );
  }

  add(){
    this.demande.correspondant = this.demandeForm.get('ctrCorrespondant').value;
    this.demande.typeDemande = this.demandeForm.get('ctrTypeDemande').value;
    this.demande.signature = this.demandeForm.get('ctrSignature').value;
    this.demande.dateDemande = this.demandeForm.get('ctrDateDemande').value;
    this.demande.dateReponse = this.demandeForm.get('ctrDateReponse').value;

    this.demandeService.update(this.demande).subscribe(
        data => {
          this.router.navigate(['/numerotation']);
        },
        err => {
          this.handleError(err);
        }
    );
  }

  


    showDialogDeatilDemande() {
      this.detailDemandeForm.reset();
      this.displayDialogDetailDemande = true;
    }

    addDetailDemande(){
      const detail = new DetailDemande();
      detail.usage = this.detailDemandeForm.get('ctrUsage').value;
      detail.format = this.detailDemandeForm.get('ctrFormat').value;
      detail.quantite = this.detailDemandeForm.get('ctrQuantite').value;

      this.demande.details.forEach((item , index) => {
        if (item.usage.code === detail.usage.code && item.format.libelle === detail.format.libelle){
          this.demande.details.splice(index, 1);
        }
      });

      this.demande.details.push(detail);
      this.displayDialogDetailDemande = false;

      if (this.demande.details.length > 0){
        this.detailValid = true;
      }else{
        this.detailValid = false;
      }
    }


    selectDetail(selectedDetail: DetailDemande){
      this.detailDemandeForm.setValue({
        ctrUsage: selectedDetail.usage,
        ctrFormat: selectedDetail.format,
        ctrQuantite: selectedDetail.quantite,
      });
      this.displayDialogDetailDemande = true;
    }

    cancel(){
      this.detailDemandeForm.reset();
    }

exportPDF(slug: string){
    this.pieceJointeService.exportPDF(slug).subscribe(
      data => {
        const fileUrl = URL.createObjectURL(data);
        window.open(fileUrl, '_blank');
      },
      err => {
        this.handleError(err);
      }
    );
  }

    

    handleError(err){
      alert(err.error.message);
      switch (err.status){        
        case 404 : {
          alert(err.error.message);
          this.router.navigateByUrl('/');
          break;
        }
        
      }
    }
    uploader(event) {
      for(let file of event.files) {
        this.uploadedFiles.push(file);
      }

    this.pieceJointeService.upload(this.uploadedFiles[0], this.demande.slug).subscribe(
        data => {             
          this.displayDialogDetailDemande = false; 
          console.log(data);
          this.demande.pieceJointes.push(data);
          this.uploadedFiles= [];
            
        },
        err => {        
          this.handleError(err);
        }
      );
    }

    delete(slug: string){
      this.pieceJointeService.delete(slug).subscribe(
        data => {
         this.demande.pieceJointes.splice(this.demande.pieceJointes.indexOf(this.demande.pieceJointes.find(item => item.slug == slug)),1);
        },
        err => {
          this.handleError(err);
        }
      );
    }

}
