import { SocieteService } from './../../service/societe.service';
import { Societe } from './../../domaine/societe';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-societe',
  templateUrl: './societe.component.html',
  styleUrls: ['./societe.component.scss']
})
export class SocieteComponent implements OnInit {

  societes: Societe[];
  selectedSociete: Societe;
  societe: Societe;
  loading: boolean;

  constructor(private societeService: SocieteService) { }

  ngOnInit(): void {
    this.selectedSociete =  new Societe();
    this.societeService.all().subscribe(
      data => {
      this.societes = data;
    });
  }

}
