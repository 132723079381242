import { CurrentUser } from './../domaine/current-user';
import { environment } from './../../../environments/environment.prod';
import { TockenService } from './tocken.service';
import { DataTocket } from './../domaine/data-tocket';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { DataLogin } from './../domaine/data-login';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataTocken } from '../domaine/data-tocken';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedInStatus = new BehaviorSubject<boolean>(this.tockenService.loggedIn());
  authStatus  = this.loggedInStatus.asObservable();

  private  currentObject = new BehaviorSubject<CurrentUser>(this.tockenService.getCurrentUser());
  current = this.currentObject.asObservable();

  constructor(private http: HttpClient , private tockenService: TockenService ,  private router: Router) {}

  login(data: DataLogin): Observable<DataTocken>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<DataTocken>(`${ environment.apiUrl }/users/signin`, data, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  /*currentUser(username: string){
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<CurrentUser>(`${ environment.apiUrl }/users/` + username).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }*/

  changeAuthStatus(status: boolean){
      this.loggedInStatus.next(status);
  }

  setCurrentUser(current: CurrentUser){
    this.currentObject.next(current);
  }

  changeCurrentUser(user: CurrentUser){
    this.currentObject.next(user);
  }

  getCurrentUser(){
    return this.currentObject.getValue();
  }

  handleError(err){
    switch (err.status){        
        case 404 : {
          return throwError(err);
        }        
      }
      return throwError(err);    
  }
}
