import { Injectable } from '@angular/core';
import { Compagnie } from '../domaine/compagnie';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompagnieService {

  constructor(private http: HttpClient) { }

  all(): Observable<Compagnie[]>{
    return this.http.get<Compagnie[]>(`${ environment.apiUrl }/compagnie/`).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }


  add(compagnie: Compagnie): Observable<Compagnie>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Compagnie>(`${ environment.apiUrl }/compagnie/`, compagnie, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  update(compagnie: Compagnie , slug: string): Observable<Compagnie>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<Compagnie>(`${ environment.apiUrl }/compagnie/` + compagnie.slug, compagnie, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  delete(slug: string): Observable<Compagnie>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<Compagnie>(`${ environment.apiUrl }/compagnie/` + slug).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }
  handleError(err: any){
    return throwError(err);
  }


  find(slug: string): Observable<Compagnie>{
    return this.http.get<Compagnie>(`${ environment.apiUrl }/compagnie/` + slug).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
    )
    );
  }
}



