import { Autorisation } from './autorisation';
export class Profil {
    slug: string;
    name: string;
    description: string;
    active: boolean;
    roles: Autorisation[];

    constructor(){
        this.roles = [];
    }
}
