import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TypeDemande } from './../domaine/type-demande';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TypeDemandeService {

  constructor(private http: HttpClient) { }

  all(): Observable<TypeDemande[]>{
    return this.http.get<TypeDemande[]>(`${ environment.apiUrl }/type-demande/`).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }


  add(criticite: TypeDemande): Observable<TypeDemande>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<TypeDemande>(`${ environment.apiUrl }/type-demande/`, criticite, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  update(criticite: TypeDemande): Observable<TypeDemande>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<TypeDemande>(`${ environment.apiUrl }/type-demande/` + criticite.id, criticite, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  delete(id: number): Observable<TypeDemande>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<TypeDemande>(`${ environment.apiUrl }/type-demande/` + id).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  handleError(err: any){
    return throwError(err);
  }
}
