import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Correspondant } from '../domaine/correspondant';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CorrespondantService {

 constructor(private http: HttpClient) { }

  all(): Observable<Correspondant[]>{
    return this.http.get<Correspondant[]>(`${ environment.apiUrl }/correspondant/`).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }


  add(correspondant: Correspondant): Observable<Correspondant>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Correspondant>(`${ environment.apiUrl }/correspondant/`, correspondant, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  update(correspondant: Correspondant , slug: string): Observable<Correspondant>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<Correspondant>(`${ environment.apiUrl }/correspondant/` + slug, correspondant, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  delete(slug: string): Observable<Correspondant>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<Correspondant>(`${ environment.apiUrl }/correspondant/` + slug).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  find(slug: string): Observable<Correspondant>{
    return this.http.get<Correspondant>(`${ environment.apiUrl }/correspondant/` + slug).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
    )
    );
  }

  handleError(err: any){
    return throwError(err);
  }
}
