import { error } from 'selenium-webdriver';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Demande } from './../domaine/demande';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { ListeDemande } from './../domaine/liste-demande';
import { environment } from 'src/environments/environment';
import { SearchDemande } from './../domaine/search-demande';

@Injectable({
  providedIn: 'root'
})
export class DemandeService {

 constructor(private http: HttpClient) { }

  all( searchDemande: SearchDemande ,page: number = 0 , limit: number = 5 ): Observable<ListeDemande>{
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('limit', limit.toString());
    params = params.append('compagnie', searchDemande.compagnie.toString());
    params = params.append('numero', searchDemande.numero.toString());
    params = params.append('dateDemandeDebut', searchDemande.dateDemandeDebut.toString());
    params = params.append('dateDemandeFin', searchDemande.dateDemandeFin.toString());
    return this.http.get<ListeDemande>(`${ environment.apiUrl }/demande/`, { params } ).pipe(
      catchError(
          err => {
             return this.handleError(err);
          }
      )
    );
  }


  add(demande: Demande): Observable<Demande>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Demande>(`${ environment.apiUrl }/demande/`, demande, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
    )
    );
  }

  find(slug: string): Observable<Demande>{
    return this.http.get<Demande>(`${ environment.apiUrl }/demande/` + slug).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
    )
    );
  }

  update(demande: Demande): Observable<Demande>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<Demande>(`${ environment.apiUrl }/demande/`, demande, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
    )
    );
  }

  delete(id: number): Observable<Demande>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<Demande>(`${ environment.apiUrl }/demande/` + id).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }


  last(): Observable<Demande>{
    return this.http.get<Demande>(`${ environment.apiUrl }/demande/last-demande`).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }



  exportPDF(id: number): Observable<Blob>{
    return this.http.get(`${ environment.apiUrl }/demande/export/pdf/` + id , {responseType: 'blob'}).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
    )
    );
  }

  handleError(err: any){
    return throwError(err);
  }
}
