import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AutorisationService } from 'src/app/utilisateur/service/autorisation.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Autorisation } from 'src/app/utilisateur/domaine/autorisation';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss']
})
export class EditRoleComponent implements OnInit {

  role: Autorisation;
  userForm: FormGroup;

  constructor(  private autorisationService: AutorisationService,
                private route: ActivatedRoute ,
                private router: Router) { }

  ngOnInit(): void {
    this.role = new Autorisation();
    const slug = this.route.snapshot.paramMap.get('slug');
    this.userForm = new FormGroup({
      ctrName : new FormControl('', Validators.required),
      ctrDescription : new FormControl('', Validators.required),
      ctrActive : new FormControl(false , Validators.required )
    });
    const role = this.autorisationService.find(slug);

    forkJoin([role]).subscribe(
      result => {

        this.role = result[0];
        this.userForm.setValue({
          ctrName : this.role.roleName,
          ctrDescription : this.role.description,
          ctrActive : this.role.active
        });

      });
  }

  edit(){
    this.role.roleName = this.userForm.get('ctrName').value;
    this.role.description = this.userForm.get('ctrDescription').value;
    this.role.active = this.userForm.get('ctrActive').value;
    this.autorisationService.add(this.role).subscribe(
      data => {
        this.router.navigate(['/administration/utilisateur/autorisation']);
      },
      err => {
        alert(err.error.message);
      }
    );
  }

}
