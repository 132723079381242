import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Usage } from '../../domaine/usage';
import { UsageService } from '../../service/usage.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  usageForm: FormGroup;
  usageEdit: Usage;
  binary: boolean;

  categories: any[] = [{name: 'Oui'}, {name: 'Non'}];
 

  constructor(private usageService: UsageService,
              private route: ActivatedRoute ,
              private router: Router) {}

  ngOnInit(): void {
    this.usageEdit = new Usage();   

    this.usageForm = new FormGroup({
      ctrCode : new FormControl(null, Validators.required),
      ctrLibelle : new FormControl(null, Validators.required),
      ctrDescription : new FormControl(null, Validators.required),
      ctrIsActif : new FormControl({name:'Non'}, Validators.required),
      
    });    


  }

  Add(){
    this.usageEdit.code = this.usageForm.get('ctrCode').value;
    this.usageEdit.libelle = this.usageForm.get('ctrLibelle').value;
    this.usageEdit.description = this.usageForm.get('ctrDescription').value;
    this.usageEdit.actif = (this.usageForm.get('ctrIsActif').value.name == 'Oui')? true: false;

    this.usageService.add(this.usageEdit).subscribe(
      data => {
        this.router.navigate(['/numerotation/administration/usage']);
      },
      err => {
        alert(err.error.message);
      }
    );
  }




  handleError(err){
      alert(err.error.message);
      switch (err.status){
        
        case 404 : {
          alert(err.error.message);
          this.router.navigateByUrl('/');
          break;
        }
        
      }
    }

}
