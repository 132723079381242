import { Message } from 'primeng/api';
import { error } from 'selenium-webdriver';
import { Profil } from './../../domaine/profil';
import { ProfilService } from './../../service/profil.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

  profils: Profil[];

  constructor(private profilService: ProfilService) { }

  ngOnInit(): void {
    this.profilService.all().subscribe(
      data => {
        this.profils = data;
      },
      err => {
        alert(err.error.Message);
      }
    );
  }

}
