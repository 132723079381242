import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './views/index/index.component';
import { AddComponent } from './views/add/add.component';
import { EditComponent } from './views/edit/edit.component';

const routes: Routes = [

  {
        path: '',
        component: IndexComponent,
        data: {
          title: 'Toutes les compagnies'
        }
      },
      {
        path: 'add',
        component: AddComponent,
        data: {
          title: 'Ajouter une compagnie'
        }
      },
      {
        path: 'edit/:slug',
        component: EditComponent,
        data: {
          title: 'Modifier une compagnie'
        }
      }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
exports: [RouterModule]
})
export class CompagnieRoutingModule { }
