import { ActivatedRoute, Router } from '@angular/router';
import { Profil } from './../../../domaine/profil';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Autorisation } from 'src/app/utilisateur/domaine/autorisation';

import { AutorisationService } from 'src/app/utilisateur/service/autorisation.service';
import { Component, OnInit } from '@angular/core';
import { ProfilService } from 'src/app/utilisateur/service/profil.service';

@Component({
  selector: 'app-add-profil',
  templateUrl: './add-profil.component.html',
  styleUrls: ['./add-profil.component.scss']
})
export class AddProfilComponent implements OnInit {

  autorisations: Autorisation[];
  profil: Profil;
  selectedAutorisations: Autorisation[];
  userForm: FormGroup;

  constructor(  private autorisationService: AutorisationService ,
                private  profilService: ProfilService,
                private route: ActivatedRoute ,
                private router: Router) { }

  ngOnInit(): void {
    this.profil = new Profil();

    this.autorisationService.all().subscribe(
      data => {
        this.autorisations = data;
      }
    );
    this.userForm = new FormGroup({
      ctrName : new FormControl('', Validators.required),
      ctrDescription : new FormControl('', Validators.required),
      ctrActive : new FormControl(false , Validators.required )
    });
  }


  add(){
    this.profil.name = this.userForm.get('ctrName').value;
    this.profil.description = this.userForm.get('ctrDescription').value;
    this.profil.active = this.userForm.get('ctrActive').value;
    this.profil.roles = this.selectedAutorisations;


    this.profilService.add(this.profil).subscribe(
      data => {
        this.router.navigate(['/administration/utilisateur/profil']);
      },
      err => {
        alert(err.error.message);
      }
    );
  }

}
