import { EditRoleComponent } from './views/autorisation/edit-role/edit-role.component';
import { AddRoleComponent } from './views/autorisation/add-role/add-role.component';
import { EditProfilComponent } from './views/profil/edit-profil/edit-profil.component';
import { AddProfilComponent } from './views/profil/add-profil/add-profil.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './views/user/user.component';
import { ProfilComponent } from './views/profil/profil.component';
import { ServiceComponent } from './views/service/service.component';
import { AutorisationComponent } from './views/autorisation/autorisation.component';
import { SocieteComponent } from './views/societe/societe.component';
import { EditUserComponent } from './views/user/edit-user/edit-user.component';
import { AddUserComponent } from './views/user/add-user/add-user.component';
import { IndexComponent } from './views/index/index.component';

const routes: Routes = [
      {
          path: '',
          component: IndexComponent,
          data: {
            title: 'administration equipement'
          }
      },
      {
          path: 'user',
          component: UserComponent,
          data: {
            title: 'administration equipement'
          }
      },
      {
          path: 'user/edit/:slug',
          component: EditUserComponent,
          data: {
            title: 'Editer utilisateur'
          }
      },
      {
        path: 'user/add',
        component: AddUserComponent,
        data: {
          title: 'Ajouter utilisateur'
        }
      },
      {
        path: 'profil',
        component: ProfilComponent,
        data: {
          title: 'Administration profil'
        }
      },
      {
        path: 'profil/edit/:slug',
        component: EditProfilComponent,
        data: {
          title: 'edit profil'
        }
      },
      {
        path: 'profil/add',
        component: AddProfilComponent,
        data: {
          title: 'edit profil'
        }
      },
      {
        path: 'service',
        component: ServiceComponent,
        data: {
          title: 'Administration Utilisateur'
        }
      },
      {
        path: 'autorisation',
        component: AutorisationComponent,
        data: {
          title: 'Administration Utilisateur'
        }
      },
      {
        path: 'autorisation/add',
        component: AddRoleComponent,
        data: {
          title: 'Ajouter autorisation'
        }
      },
      {
        path: 'autorisation/edit/:slug',
        component: EditRoleComponent,
        data: {
          title: 'Editer autorisation'
        }
      },
      {
        path: 'societe',
        component: SocieteComponent,
        data: {
          title: 'Administration Utilisateur'
        }
      }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],






exports: [RouterModule]
})
export class UtilisateurRoutingModule { }
