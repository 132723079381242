import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';
import { PieceJointe } from './../domaine/piece-jointe';

@Injectable({
  providedIn: 'root'
})
export class PieceJointeService {

  constructor(private http: HttpClient) { }

  exportPDF(slug: string): Observable<Blob>{
    return this.http.get(`${ environment.apiUrl }/piece_jointe/export/` + slug , {responseType: 'blob'}).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
    )
    );
  }


  upload(file: any , slug: string):Observable<any> {
      //const options = { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }) };
      const formData = new FormData(); 
      formData.append("file", file, file.name);        
      return this.http.post(`${ environment.apiUrl }/piece_jointe/` + slug , formData ).pipe(
        catchError(
          err => {
            return this.handleError(err);
          }
        )
      );
  }

  delete(slug: string): Observable<PieceJointe>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<PieceJointe>(`${ environment.apiUrl }/piece_jointe/` + slug).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }
  
  handleError(err: any){
    return throwError(err);
  }
}
