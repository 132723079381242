export class Compagnie {
    id:number;
    slug:string;
    code:string;
    libelle:string;
    raisonSociale: string;
    adresse : string;
    ville: string;
    pays: string;
    actif: boolean;
}
