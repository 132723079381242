import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Compagnie } from '../../domaine/compagnie';
import { CompagnieService } from './../../service/compagnie.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  compagnieForm: FormGroup;
  compagnie: Compagnie;
 

  categories: any[] = [{name: 'Oui'}, {name: 'Non'}];
 

  constructor(private compagnieService: CompagnieService,
              private route: ActivatedRoute ,
              private router: Router) {}

  ngOnInit(): void {    
    this.compagnieForm = new FormGroup({
      code : new FormControl(null, Validators.required),
      libelle : new FormControl(null, Validators.required),
      raisonSociale : new FormControl(null, Validators.required),
      adresse : new FormControl(null, Validators.required),
      ville: new FormControl(null, Validators.required),
      pays : new FormControl(null, Validators.required),
      actif : new FormControl({name:'Non'}, Validators.required),
      
    });
  }

  add(){

    this.compagnie = new Compagnie();

    this.compagnie.code = this.compagnieForm.get('code').value;
    this.compagnie.libelle = this.compagnieForm.get('libelle').value;
    this.compagnie.raisonSociale = this.compagnieForm.get('raisonSociale').value;
    this.compagnie.adresse = this.compagnieForm.get('adresse').value;
    this.compagnie.ville = this.compagnieForm.get('ville').value;
    this.compagnie.pays = this.compagnieForm.get('pays').value;
    this.compagnie.actif = (this.compagnieForm.get('actif').value.name == 'Oui')? true: false; 
    this.compagnieService.add(this.compagnie).subscribe(
      data => {
        this.router.navigate(['/numerotation/administration/compagnie']);
      },
      err => {
        alert(err.error.message);
      }
    );
  }

 


  handleError(err){
      alert(err.error.message);
      switch (err.status){
        
        case 404 : {
          alert(err.error.message);
          this.router.navigateByUrl('/');
          break;
        }
        
      }
    }

}
