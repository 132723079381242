import { AuthService } from './../../../authentication/service/auth.service';
import { TockenService } from './../../../authentication/service/tocken.service';
import { error } from 'selenium-webdriver';
import { Component, OnInit } from '@angular/core';
import { DemandeService } from './../../service/demande.service';
import { Demande } from './../../domaine/demande';
import { Router, ActivatedRoute } from '@angular/router';
import { LazyLoadEvent, Message } from 'primeng/api';
import { Usage } from './../../domaine/usage';
import { CompagnieService } from './../../service/compagnie.service';
import { Compagnie } from './../../domaine/compagnie';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SearchDemande } from './../../domaine/search-demande';

@Component({
  selector: 'app-all-demande',
  templateUrl: './all-demande.component.html',
  styleUrls: ['./all-demande.component.scss']
})
export class AllDemandeComponent implements OnInit {

  demandes: Demande[];
  selectedDemande: Demande;
  totalRecords: number;
  loading: boolean;  


  compagnies: Compagnie[];
  selectedCompagnie: Compagnie;

   demandeForm: FormGroup;

   searchDemande: SearchDemande;

  constructor(  private demandeService: DemandeService ,
                private route: ActivatedRoute ,
                private router: Router ,
                private tockenService: TockenService ,
                private authService: AuthService,
                private compagnieService: CompagnieService) {}

  ngOnInit(): void {
    this.totalRecords = 0;
    this.loading = true;

    this.searchDemande = new SearchDemande();

    this.demandeForm = new FormGroup({
      ctrCompagnie: new FormControl(null),
      ctrNumero: new FormControl(null),
      ctrDateDemandeDebut : new FormControl(null),
      ctrDateDemandeFin : new FormControl(null)     
    });

    this.demandeService.all(this.searchDemande).subscribe(
      listDemande => {
        this.demandes = listDemande.demandes;
        this.totalRecords = listDemande.totalRecord;
        this.loading = false;
      },
      err => {
        this.handleError(err);
       }
      );

      this.compagnieService.all().subscribe(compagnies => this.compagnies = compagnies);
  }

  lazyLoad(event: LazyLoadEvent){
    this.selectedDemande = null;
    this.loading = true;
    setTimeout(() => {
      if (this.demandes) {
        this.demandeService.all(this.searchDemande,event.first /  event.rows + 1 , event.rows ).subscribe(
          listDemande => {
            this.demandes = listDemande.demandes;
            this.totalRecords = listDemande.totalRecord;
            this.loading = false;
          },
          err => {
            // alert(err.error.Message);
            this.router.navigate(['/']);
          }
        );
     }
    }, 100);
  }

  exportPDF(slug: number){
    this.demandeService.exportPDF(slug).subscribe(
      data => {
        const fileUrl = URL.createObjectURL(data);
        window.open(fileUrl, '_blank');
      },
      err => {
        this.handleError(err);
      }
    );
  }
  search(){
    this.searchDemande.compagnie = (this.demandeForm.get('ctrCompagnie').value?.id)? this.demandeForm.get('ctrCompagnie').value.id : -1;
    this.searchDemande.numero = (this.demandeForm.get('ctrNumero')?.value)? this.demandeForm.get('ctrNumero').value : 0;
    this.searchDemande.dateDemandeDebut = (this.demandeForm.get('ctrDateDemandeDebut')?.value)? this.demandeForm.get('ctrDateDemandeDebut').value : '';
    this.searchDemande.dateDemandeFin = (this.demandeForm.get('ctrDateDemandeFin')?.value)? this.demandeForm.get('ctrDateDemandeFin').value : '';
    this.demandeService.all(this.searchDemande).subscribe(
      listDemande => {
        this.demandes = listDemande.demandes;
        this.totalRecords = listDemande.totalRecord;
        this.loading = false;
      },
      err => {
        this.handleError(err);
       }
      );    
  }

  onRowSelect($event){
    console.log(this.selectedDemande);
  }

  handleError(err){
    switch (err.status){      
      case 404 : {
        alert(err.error.message);
        this.router.navigateByUrl('/');
        break;
      }      
    }
  }
}
