import { LogoutComponent } from './authentication/view/logout/logout.component';
import { AfterAuthGuard } from './authentication/after-auth.guard';
import { GuardGuard } from './authentication/guard.guard';
import { NotfoundComponent } from './authentication/view/notfound/notfound.component';
import { LoginComponent } from './authentication/view/login/login.component';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';




import {AppMainComponent} from './app.main.component';
import { AuthGuard } from './authentication/auth.guard';



@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    {path: 'numerotation', loadChildren: './numerotation/numerotation.module#NumerotationModule'},
                    {path: 'administration/utilisateur', loadChildren: './utilisateur/utilisateur.module#UtilisateurModule'},
                    {path: 'numerotation/administration/compagnie', loadChildren: './compagnie/compagnie.module#CompagnieModule'},
                    {path: 'numerotation/administration/usage', loadChildren: './usage/usage.module#UsageModule'},
                ],
                canActivate: [AuthGuard]
            },
            // {path: 'error', component: AppErrorComponent},
            // {path: 'accessdenied', component: AppAccessdeniedComponent},
            {path: 'login', component: LoginComponent , canActivate: [AfterAuthGuard]},
            {path: 'logout', component: LogoutComponent , canActivate: [AuthGuard]},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
