import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-admin-numerotation',
  templateUrl: './admin-numerotation.component.html',
  styleUrls: ['./admin-numerotation.component.scss']
})
export class AdminNumerotationComponent implements OnInit {


  products: any;

    sortOptions: any;

    sortOrder: number;

    sortField: string;

    constructor( private primeNGConfig: PrimeNGConfig) { }

    ngOnInit() {
        this.products = [
		{
			
			"name": "USAGE",
			"description": "Paramétrage et administration usages",
			"image": "bamboo-watch.jpg"	,
			"link"	: "/numerotation/administration/usage"	
		},
		{
			
			"name": "CORRESPONDANT",
			"description": "Paramétrage et administration des correspondants",
			"image": "black-watch.jpg",
			"link"	: "/numerotation/administration/correspondant"				
		},
		{
			
			"name": "TYPE DEMANDE",
			"description": "Paramétrage et administration des types demandes",
			"image": "blue-band.jpg",
			"link"	: "/numerotation/administration/type"	
			
		},
		{
			
			"name": "SIGNATAIRE",
			"description": "Paramétrage et administration des signataires",
			"image": "blue-t-shirt.jpg",
			"link"	: "/numerotation/administration/signature"	
			
		},
		{
			
			"name": "FORMAT",
			"description": "Paramétrage et administration des formats",
			"image": "bracelet.jpg",
			"link"	: "/numerotation/administration/format"	
			
		},
		{
			
			"name": "COMPAGNIE",
			"description": "Paramétrage et administration compagnie",
			"image": "bracelet.jpg",
			"link"	: "/numerotation/administration/compagnie"	
			
		},
		
	]


       

       
    }
    
    
}