export class SearchDemande {
    compagnie: number;
    dateDemandeDebut: string;
    dateDemandeFin: string;
    numero: number;

    constructor(){
        this.compagnie = -1;
        this.dateDemandeDebut = '';
        this.dateDemandeFin = '';
        this.numero = 0;
    }
}
