import { Compagnie } from "./compagnie";
import { Personne } from "./personne";

export class Correspondant {
    id: number;
    slug: string;
    civilite: string;
    nom: string;
    prenom: string;
    email: string;
    telephone: string;
    fonction: string;
    personne : Personne;
    compagnie: Compagnie;
    actif: boolean
    constructor(){
        this.personne = new Personne();
    }
}
