import { Demande } from "./demande";
import { Format } from "./format";
import { Usage } from "./usage";

export class DetailDemande {
    id: number;
    slug: string;
    usage: Usage;
    format: Format;
    quantite: number;
    debut : number;
    fin: number;
    demande: Demande;
    constructor(){
        this.usage = new Usage();
        this.format = new Format();
    }
    comparTo(detail: DetailDemande): boolean{
        if(this.usage.code === detail.usage.code){
            return true;
        }else{
            return false;
        }
    }
}
