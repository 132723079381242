import { ActivatedRoute, Router } from '@angular/router';
import { UtilisateurService } from './../../../service/utilisateur.service';
import { UserAdd } from './../../../domaine/user-add';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Societe } from './../../../domaine/societe';
import { Service } from './../../../domaine/service';
import { Profil } from './../../../domaine/profil';
import { Autorisation } from 'src/app/utilisateur/domaine/autorisation';
import { AutorisationService } from 'src/app/utilisateur/service/autorisation.service';
import { ProfilService } from 'src/app/utilisateur/service/profil.service';
import { ServiceService } from 'src/app/utilisateur/service/service.service';
import { SocieteService } from 'src/app/utilisateur/service/societe.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  societes: Societe[];
  services: Service[];
  checked: boolean;
  selectedValue: string;
  userAdd: UserAdd;
  autorisations: Autorisation[];
  selectedAutorisations: Autorisation[];

  userForm: FormGroup;

  constructor(private autorisationService: AutorisationService ,
              private profilService: ProfilService,
              private serviceService: ServiceService,
              private societeService: SocieteService,
              private utilisateurService: UtilisateurService,
              private route: ActivatedRoute ,
              private router: Router,
              ) {

                this.checked = false;
                this.selectedValue = 'Non';

  }

  ngOnInit(): void {
    this.userAdd = new UserAdd();
    this.autorisationService.all().subscribe(
      data => {
        this.autorisations = data;
      }
    );
    this.serviceService.all().subscribe(services => {
      this.services = services;
    });
    this.societeService.all().subscribe(societes => {
      this.societes = societes;
    });

    this.userForm = new FormGroup({
      ctrEmail : new FormControl(' ', Validators.required),
      ctrPassword : new FormControl('', Validators.required),
      ctrPasswordConfirm : new FormControl('', Validators.required),
      ctrNom : new FormControl('', Validators.required),
      ctrPrenom : new FormControl('', Validators.required),
      ctrService : new FormControl(null, Validators.required),
      ctrSociete : new FormControl(null, Validators.required),
      ctrTelephoneFixe : new FormControl(null),
      ctrTelephoneMobile : new FormControl(null),
      ctrActive : new FormControl(this.userAdd.active , Validators.required )
    });
  }

  add(){
    this.userAdd.email = this.userForm.get('ctrEmail').value;
    this.userAdd.nom = this.userForm.get('ctrNom').value;
    this.userAdd.prenom = this.userForm.get('ctrPrenom').value;
    this.userAdd.societe = this.userForm.get('ctrSociete').value.slug;
    this.userAdd.service = this.userForm.get('ctrService').value.id;
    this.userAdd.password = this.userForm.get('ctrPassword').value;
    this.userAdd.telephoneFixe = this.userForm.get('ctrTelephoneFixe').value;
    this.userAdd.telephonePortable = this.userForm.get('ctrTelephoneMobile').value;
    this.userAdd.roles = this.selectedAutorisations;
    this.utilisateurService.add(this.userAdd).subscribe(
      data => {
        this.router.navigate(['/administration/utilisateur/user']);
      },
      err => {
        alert(err.error.message);
      }
    );
  }

}
