import { ActivatedRoute, Router } from '@angular/router';
import { AutorisationService } from 'src/app/utilisateur/service/autorisation.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Autorisation } from 'src/app/utilisateur/domaine/autorisation';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {

  role: Autorisation;
  userForm: FormGroup;

  constructor(  private autorisationService: AutorisationService,
                private route: ActivatedRoute ,
                private router: Router) { }

  ngOnInit(): void {
    this.role = new Autorisation();
    this.userForm = new FormGroup({
      ctrName : new FormControl('', Validators.required),
      ctrDescription : new FormControl('', Validators.required),
      ctrActive : new FormControl(false , Validators.required )
    });
  }

  add(){
    this.role.roleName = this.userForm.get('ctrName').value;
    this.role.description = this.userForm.get('ctrDescription').value;
    this.role.active = this.userForm.get('ctrActive').value;
    this.autorisationService.add(this.role).subscribe(
      data => {
        this.router.navigate(['/administration/utilisateur/autorisation']);
      }
    );
  }

}
