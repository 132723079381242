import { environment } from './../../../environments/environment';
import { Profil } from './../domaine/profil';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfilService {

  constructor(private http: HttpClient) { }

  all(): Observable<Profil[]>{
    return this.http.get<Profil[]>(`${ environment.apiUrl }/profil/`).pipe(
      map(profils => profils)
    );
  }

  find(slug: string): Observable<Profil>{
    return this.http.get<Profil>(`${ environment.apiUrl }/profil/` + slug , ).pipe(
      map(profil => profil)
    );
  }


  add(profil: Profil): Observable<Profil>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Profil>(`${ environment.apiUrl }/profil/`, profil, options).pipe();
  }

  update(slug: string , profil: Profil): Observable<Profil>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<Profil>(`${ environment.apiUrl }/profil/` + profil.slug, profil, options).pipe();
  }

  delete(slug: string): Observable<Profil>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<any>(`${ environment.apiUrl }/profil/` + slug).pipe();
  }
}
