import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usage } from '../domaine/usage';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsageService {

  constructor(private http: HttpClient) { }

  all(): Observable<Usage[]>{
    return this.http.get<Usage[]>(`${ environment.apiUrl }/usage/`).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }


  add(usage: Usage): Observable<Usage>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Usage>(`${ environment.apiUrl }/usage/`, usage, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  update(usage: Usage , slug: string): Observable<Usage>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<Usage>(`${ environment.apiUrl }/usage/` + slug, usage, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
    )
    );
  }

  delete(id: number): Observable<Usage>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<Usage>(`${ environment.apiUrl }/usage/` + id).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  find(slug: string): Observable<Usage>{
    return this.http.get<Usage>(`${ environment.apiUrl }/usage/` + slug).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
    )
    );
  }

  handleError(err: any){
    return throwError(err);
  }
}
