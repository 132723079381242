import { Component, OnInit } from '@angular/core';
import { CorrespondantService } from './../../../service/correspondant.service';
import { Correspondant } from './../../../domaine/correspondant';

@Component({
  selector: 'app-correspondant',
  templateUrl: './correspondant.component.html',
  styleUrls: ['./correspondant.component.scss']
})
export class CorrespondantComponent implements OnInit {

  correspondants: Correspondant[];

  constructor(private correspondantService: CorrespondantService) { }

  ngOnInit(): void {
    this.correspondantService.all().subscribe(
      data=>{
        this.correspondants = data;
      },
      err => {
        alert(err.error.Message);
      }
    );
  }

}
