import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Compagnie } from '../../domaine/compagnie';
import { ActivatedRoute, Router } from '@angular/router';
import { CompagnieService } from '../../service/compagnie.service';
import { ReactiveFormsModule,} from '@angular/forms';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

 compagnieForm: FormGroup;
  compagnie: Compagnie;
  binary: boolean;

  categories: any[] = [{name: 'Oui'}, {name: 'Non'}];
 

  constructor(private compagnieService: CompagnieService,
              private route: ActivatedRoute ,
              private router: Router) {}

  ngOnInit(): void {
    
    const slug = this.route.snapshot.paramMap.get('slug');    

    this.compagnieForm = new FormGroup({
      code : new FormControl(null, Validators.required),
      libelle : new FormControl(null, Validators.required),
      raisonSociale : new FormControl(null, Validators.required),
      adresse : new FormControl(null, Validators.required),
      ville: new FormControl(null, Validators.required),
      pays : new FormControl(null, Validators.required),
      actif : new FormControl({name:'Non'}, Validators.required),
      
    });

    this.compagnieService.find(slug).subscribe(
      data=>{
        this.compagnie = data;        
        this.compagnieForm.setValue({
          code : this.compagnie.code,
          libelle : this.compagnie.libelle,
          raisonSociale : this.compagnie.raisonSociale,
          adresse : this.compagnie.adresse,
          ville: this.compagnie.ville,
          pays : this.compagnie.pays,
          actif : {name:(this.compagnie.actif)? 'Oui': 'Non'}              
      });

      },
      err =>{
        this.handleError(err);
      }
    );

    


  }

  update(){
    this.compagnie.code = this.compagnieForm.get('code').value;
    this.compagnie.libelle = this.compagnieForm.get('libelle').value;
    this.compagnie.raisonSociale = this.compagnieForm.get('raisonSociale').value;
    this.compagnie.ville = this.compagnieForm.get('adresse').value;
    this.compagnie.ville = this.compagnieForm.get('ville').value;
    this.compagnie.pays = this.compagnieForm.get('pays').value;
    this.compagnie.actif = (this.compagnieForm.get('actif').value.name == 'Oui')? true: false;   

    this.compagnieService.update(this.compagnie , this.compagnie.slug).subscribe(
      data => {
        this.router.navigate(['/numerotation/administration/compagnie']);
      },
      err => {
        alert(err.error.message);
      }
    );
  }

  delete(){
    this.compagnieService.delete(this.compagnie.slug).subscribe(
      data => {
        this.router.navigate(['/numerotation/administration/compagnie']);
      },
      err => {
        alert(err.error.Message);
      }
    );
  }


  handleError(err){
      alert(err.error.message);
      switch (err.status){
        
        case 404 : {
          alert(err.error.message);
          this.router.navigateByUrl('/');
          break;
        }
        
      }
    }

}
