import { AuthService } from 'src/app/authentication/service/auth.service';
import { TockenService } from './../../../authentication/service/tocken.service';
import { Component, OnInit } from '@angular/core';
import { Compagnie } from './../../domaine/compagnie';
import { Usage } from './../../domaine/usage';
import { Format } from './../../domaine/format';
import { Correspondant } from '../../domaine/correspondant';
import { Signature } from './../../domaine/signature';
import { TypeDemande } from '../../domaine/type-demande';
import { UsageService } from './../../service/usage.service';
import { FormatService } from './../../service/format.service';
import { CorrespondantService } from '../../service/correspondant.service';
import { SignatureService } from '../../service/signature.service';
import { TypeDemandeService } from '../../service/type-demande.service';
import { Demande } from './../../domaine/demande';
import { DetailDemande } from '../../domaine/detail-demande';
import { DemandeService } from './../../service/demande.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Message, MessageService, PrimeNGConfig } from 'primeng/api';
import { CompagnieService } from './../../service/compagnie.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { forkJoin } from 'rxjs';



@Component({
  selector: 'app-add-demande',
  templateUrl: './add-demande.component.html',
  styleUrls: ['./add-demande.component.scss']
})
export class AddDemandeComponent implements OnInit {

  compagnies: Compagnie[];
  selectedCompagnie: Compagnie;

  usages: Usage[];

  formats: Format[];

  correspondants: Correspondant[];
  allCorrespondant: Correspondant[];
  selectedCorrespondant: Correspondant;

  signatures: Signature[];

  typeDemandes: TypeDemande[];

  demande: Demande = new Demande();

  titre: string;

  detailValid: boolean ;

  displayDialogDetailDemande = false;

  isEdit: boolean;

  demandeForm: FormGroup;
  detailDemandeForm: FormGroup;

  msgs1: Message[];

  currentRoute: string;
  dateExp = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;

  constructor(
    private usageService: UsageService ,
    private formatService: FormatService ,
    private correspondantService: CorrespondantService,
    private signatureService: SignatureService,
    private typeDemandeService: TypeDemandeService,
    private demandeService: DemandeService,
    private formBuilder: FormBuilder,
    private primengConfig: PrimeNGConfig,
    private compagnieService: CompagnieService,
    private route: ActivatedRoute ,
    private router: Router,
    private tockenService: TockenService ,
    private authService: AuthService) {
      this.isEdit = false;
    }

  ngOnInit(): void {

    this.currentRoute = this.router.url;
    this.demandeForm = new FormGroup({
      ctrCompagnie: new FormControl(null, Validators.required),
      ctrCorrespondant : new FormControl(null, Validators.required),
      ctrTypeDemande : new FormControl(null, Validators.required),
      ctrSignature : new FormControl(null, Validators.required),
      ctrDateDemande : new FormControl(new Date()),
      ctrDateReponse : new FormControl(new Date(), Validators.required)
    });
    this.detailDemandeForm = new FormGroup({
      ctrUsage: new FormControl(null, Validators.required),
      ctrFormat: new FormControl(null, Validators.required),
      ctrQuantite: new FormControl(null, Validators.required)
    });

    this.titre = 'Nouvelle demande';
    this.detailValid = false;
    this.correspondantService.all().subscribe(
      correspondants => {
        this.allCorrespondant = correspondants;
      },
      err => {
        this.handleError(err);
      }
      );
    this.typeDemandeService.all().subscribe(typeDemandes => this.typeDemandes = typeDemandes );
    this.usageService.all().subscribe(usages => this.usages = usages );
    this.formatService.all().subscribe(formats => this.formats = formats );
    this.signatureService.all().subscribe(signatures => this.signatures = signatures );
    this.compagnieService.all().subscribe(compagnies => this.compagnies = compagnies);
  }

  add(){
    this.demande.correspondant = this.demandeForm.get('ctrCorrespondant').value;
    this.demande.typeDemande = this.demandeForm.get('ctrTypeDemande').value;
    this.demande.signature = this.demandeForm.get('ctrSignature').value;
    this.demande.dateDemande = this.demandeForm.get('ctrDateDemande').value;
    this.demande.dateReponse = this.demandeForm.get('ctrDateReponse').value;

    this.demandeService.add(this.demande).subscribe(
        data => {
          this.router.navigate(['/numerotation']);
        },
        err => {
          alert(err.error.message);
        }
      );
  }

  changeCompagnie(){
    const id = this.demandeForm.get('ctrCompagnie').value.id;
    this.correspondants = [];
    for (const correspondant of this.allCorrespondant){
      if (correspondant.compagnie.id === id){
        this.correspondants.push(correspondant);
      }
    }
  }


    showDialogDeatilDemande() {
      this.detailDemandeForm.reset();
      this.displayDialogDetailDemande = true;
    }

    addDetailDemande(){
      const detail = new DetailDemande();
      detail.usage = this.detailDemandeForm.get('ctrUsage').value;
      detail.format = this.detailDemandeForm.get('ctrFormat').value;
      detail.quantite = this.detailDemandeForm.get('ctrQuantite').value;
      this.demande.details.forEach((item , index) => {
        if (item.usage.code === detail.usage.code && item.format.libelle === detail.format.libelle){
          this.demande.details.splice(index, 1 );
        }
      });
      this.demande.details.push(detail);
      this.displayDialogDetailDemande = false;

      if (this.demande.details.length > 0){
        this.detailValid = true;
      }else{
        this.detailValid = false;
      }
    }


    selectDetail(selectedDetail: DetailDemande){
      this.detailDemandeForm.setValue({
        ctrUsage: selectedDetail.usage,
        ctrFormat: selectedDetail.format,
        ctrQuantite: selectedDetail.quantite,
      });
      this.displayDialogDetailDemande = true;
    }

    cancel(){
      this.detailDemandeForm.reset();
    }


    deleteDetail(selectedDetail: DetailDemande){
       this.demande.details.forEach((item , index) => {
        if (item.usage.code === selectedDetail.usage.code){
          this.demande.details.splice(index, 1);
        }
        });
       if (this.demande.details.length > 0){
          this.detailValid = true;
       }else{
          this.detailValid = false;
       }
    }

    delete(){
      this.demandeService.delete(this.demande.id).subscribe(
        data => {
          this.router.navigate(['/numerotation']);
        },
        err => {
          alert(err.error.message);
        }
      );
    }

    handleError(err){
      switch (err.status){        
        case 404 : {
          alert(err.error.message);
          this.router.navigateByUrl('/');
          break;
        }
        
      }
    }
}

