import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Signature } from './../domaine/signature';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {

  constructor(private http: HttpClient) { }

  all(): Observable<Signature[]>{
    return this.http.get<Signature[]>(`${ environment.apiUrl }/signature/`).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }


  add(criticite: Signature): Observable<Signature>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Signature>(`${ environment.apiUrl }/signature/`, criticite, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  update(criticite: Signature): Observable<Signature>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<Signature>(`${ environment.apiUrl }/signature/` + criticite.id, criticite, options).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }

  delete(id: number): Observable<Signature>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<Signature>(`${ environment.apiUrl }/signature/` + id).pipe(
      catchError(
        err => {
           return this.handleError(err);
        }
      )
    );
  }
  handleError(err: any){
    return throwError(err);
  }
}
