import { Component, OnInit } from '@angular/core';
import { Usage } from '../../domaine/usage';
import { UsageService } from '../../service/usage.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

   usages: Usage[];

  constructor(private usageService: UsageService) { }

  ngOnInit(): void {
    this.usageService.all().subscribe(
      data=>{
        this.usages = data;
      },
      err => {
        alert(err.error.Message);
      }
    );
  }

}
