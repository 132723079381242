export class Societe {
   id: number;
   raisonSociale: string;
   adresse: string;
   codePostal: string;
   ville: string;
   pays: string;
   telephone: string;
   fax: string;
   email: string;
   active: boolean;
}
