import { Autorisation } from 'src/app/utilisateur/domaine/autorisation';
export class UserEdit {
    slug: string;
    nom: string;
    prenom: string;
    password: string;
    email: string;
    telephoneFixe: string;
    telephonePortable: string;
    service: number;
    societe: string;
    active: boolean;
    roles: Autorisation[];
}
