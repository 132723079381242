import { Autorisation } from './autorisation';
import { Societe } from './societe';
import { Service } from './service';
import { Profil } from './profil';
export class User {
    slug: string;
    username: string;
    nom: string;
    prenom: string;
    password: string;
    email: string;
    telephoneFixe: string;
    telephonePortable: string;
    service: Service;
    fonction: string;
    societe: Societe;
    roles: Autorisation[];
    active: boolean;
}
