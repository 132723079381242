import { Service } from './../domaine/service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http: HttpClient) { }

  all(): Observable<Service[]>{
    return this.http.get<Service[]>(`${ environment.apiUrl }/service/`).pipe(
      map(services => services)
    );
  }


  add(service: Service): Observable<Service>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Service>(`${ environment.apiUrl }/service/`, service, options).pipe();
  }

  update(service: Service): Observable<Service>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<Service>(`${ environment.apiUrl }/service/` + service.id, service, options).pipe();
  }

  delete(id: number): Observable<Service>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<Service>(`${ environment.apiUrl }/service/` + id).pipe();
  }
}
