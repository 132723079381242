export class CurrentUser {
    username: string ;
    nom: string ;
    prenom: string ;
    email: string ;
    telephoneFixe: string ;
    telephonePortable: string ;
    fonction: string ;
    service: string ;
    societe: string ;
    roles: string[] ;
}
