import { EditDemandeComponent } from './views/edit-demande/edit-demande.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddDemandeComponent } from './views/add-demande/add-demande.component';
import { AllDemandeComponent } from './views/all-demande/all-demande.component';
import { AdminNumerotationComponent } from './views/administration/admin-numerotation/admin-numerotation.component';
import { FormatComponent } from './views/administration/format/format.component';
import { CorrespondantComponent } from './views/administration/correspondant/correspondant.component';
import { TypeComponent } from './views/administration/type/type.component';
import { SignatureComponent } from './views/administration/signature/signature.component';

import { AddCorrespondantComponent } from './views/administration/correspondant/add-correspondant/add-correspondant.component';
import { EditCorrespondantComponent } from './views/administration/correspondant/edit-correspondant/edit-correspondant.component';
import { ViewComponent } from './views/view/view.component';

const routes: Routes = [
      {
        path: '',
        component: AllDemandeComponent,
        data: {
          title: 'Tous les demandes'
        }
      },
      {
        path: 'add',
        component: AddDemandeComponent,
        data: {
          title: 'Ajouter une nouvelle demande'
        }
      },
      {
        path: 'edit',
        component: EditDemandeComponent,
        data: {
          title: 'Modifier une demande'
        }
      },
      {
        path: 'view/:slug',
        component: ViewComponent,
        data: {
          title: 'Consulter une demande'
        }
      },
      {
        path: 'administration',
        component: AdminNumerotationComponent,
        data: {
          title: 'Administration numerotation'
        },
      },
      
      
      
      {
        path: 'administration/format',
        component: FormatComponent,
        data: {
          title: 'Administration numerotation format'
        }        
      },
      {
        path: 'administration/correspondant',
        component: CorrespondantComponent,
        data: {
          title: 'Administration numerotation correspondant'
        }
        
      },
      {
        path: 'administration/correspondant/add',
        component: AddCorrespondantComponent,
        data: {
          title: 'Administration numerotation correspondant'
        }
        
      },
      {
        path: 'administration/correspondant/edit/:slug',
        component: EditCorrespondantComponent,
        data: {
          title: 'Administration numerotation correspondant'
        }
        
      },
      {
        path: 'administration/type',
        component: TypeComponent,
        data: {
          title: 'Administration numerotation type '
        }
        
      },
      {
        path: 'administration/signature',
        component: SignatureComponent,
        data: {
          title: 'Administration numerotation Signature '
        }
        
      }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],











exports: [RouterModule]
})
export class NumerotationRoutingModule { }
