import { Router } from '@angular/router';
import { TockenService } from './../../service/tocken.service';
import { DataLogin } from './../../domaine/data-login';
import { DataTocket } from './../../domaine/data-tocket';
import { AuthService } from './../../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators , ReactiveFormsModule  } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DataTocken } from '../../domaine/data-tocken';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  dataLogin: DataLogin;
 

  loginForm = new FormGroup({
    username: new FormControl(null , Validators.required ),
    password: new FormControl(null , Validators.required )
  });

  constructor(private authService: AuthService , private tockenService: TockenService , private route: Router , private router: Router) { }

  ngOnInit(): void {
    this.dataLogin = new DataLogin();
  }

  login(){
    this.dataLogin.username = this.loginForm.get('username').value;
    this.dataLogin.password = this.loginForm.get('password').value;
    this.authService.login(this.dataLogin).subscribe(
      data => {
        this.handleResponse(data);
      },
      err => {
        this.handleError(err);
      }

    );
  }

  handleResponse(dataTocken: DataTocken){
    this.tockenService.handle(dataTocken);
    this.authService.changeAuthStatus(true); 
    this.route.navigateByUrl('/');
  }

  handleError(err){
      switch (err.status){        
        case 404 : {
          alert(err.error.message);
          this.router.navigateByUrl('/');
          break;
        }        
      }
  }

}
