import { UserEdit } from './../domaine/user-edit';
import { UserAdd } from './../domaine/user-add';
import { map } from 'rxjs/operators';
import { User } from './../domaine/user';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Users } from './../domaine/users';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UtilisateurService {

  constructor(private http: HttpClient) { }

  all(page: number = 0 , limit: number = 5): Observable<Users>{
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('limit', limit.toString());
    return this.http.get<Users>(`${ environment.apiUrl }/users/`, {params}).pipe(
      map(users => users)
    );
  }

  add(userAdd: UserAdd): Observable<User>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<User>(`${ environment.apiUrl }/users/signup/`, userAdd, options).pipe();
  }
  update(userEdit: UserEdit , slug: string): Observable<User>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<User>(`${ environment.apiUrl }/users/` + slug, userEdit, options).pipe();
  }

  delete(slug: string): Observable<User>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<User>(`${ environment.apiUrl }/users/user/` + slug , options).pipe();
  }

  getTechnicien(profil: string): Observable<User[]>{
    return this.http.get<User[]>(`${ environment.apiUrl }/users/profil/` + profil).pipe(
      map(utilisateurs => utilisateurs)
    );
  }

  find(slug: string): Observable<User>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<User>(`${ environment.apiUrl }/users/user/` + slug, options).pipe();
  }
}
