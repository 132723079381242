import { Message } from 'primeng/api';
import { error } from 'selenium-webdriver';
import { AutorisationService } from 'src/app/utilisateur/service/autorisation.service';
import { Autorisation } from 'src/app/utilisateur/domaine/autorisation';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-autorisation',
  templateUrl: './autorisation.component.html',
  styleUrls: ['./autorisation.component.scss']
})
export class AutorisationComponent implements OnInit {

  roles: Autorisation[];

  constructor(private autorisationService: AutorisationService) { }

  ngOnInit(): void {
    this.autorisationService.all().subscribe(
      data => {
        this.roles = data;
      }
    );
  }

}
