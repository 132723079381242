import { Autorisation } from 'src/app/utilisateur/domaine/autorisation';
export class UserAdd {
    nom: string;
    prenom: string;
    password: string;
    email: string;
    telephoneFixe: string;
    telephonePortable: string;
    service: number;
    societe: string;
    profil: number;
    active: boolean;
    roles: Autorisation[];

    constructor(){
        this.active = false;
    }
}
