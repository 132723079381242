import { Compagnie } from './compagnie';
import { Correspondant } from './correspondant';
import { DetailDemande } from './detail-demande';
import { TypeDemande } from './type-demande';
import { Signature } from './signature';
import { PieceJointe } from './piece-jointe';
export class Demande {
    id: number;
    slug: string;
    dateDemande: Date;
    reference: string;
    numero: number;
    dateReponse: Date;
    correspondant: Correspondant;
    typeDemande: TypeDemande;
    signature: Signature;
    debut: number;
    fin: number;
    details: DetailDemande[];
    pieceJointes: PieceJointe[];


    constructor(){
        this.details = [];
        this.pieceJointes = [];
        this.correspondant = new Correspondant();
        this.typeDemande = new TypeDemande();
        this.signature = new Signature();
        this.dateDemande = new Date();
        this.dateReponse = new Date();
    }

    addDetaiDemande(detail: DetailDemande){
        this.details.push(detail);
    }

} 
