import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Autorisation } from './../domaine/autorisation';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AutorisationService {

  constructor(private http: HttpClient) { }

  all(): Observable<Autorisation[]>{
    return this.http.get<Autorisation[]>(`${ environment.apiUrl }/role/`).pipe(
      map(services => services)
    );
  }

  find(slug: string): Observable<Autorisation>{
    return this.http.get<Autorisation>(`${ environment.apiUrl }/role/` + slug).pipe();
  }


  add(autorisation: Autorisation): Observable<Autorisation>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Autorisation>(`${ environment.apiUrl }/role/`, autorisation, options).pipe();
  }

  update(autorisation: Autorisation , slug: string): Observable<Autorisation>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<Autorisation>(`${ environment.apiUrl }/role/` + slug, autorisation, options).pipe();
  }

  delete(slug: string): Observable<Autorisation>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<Autorisation>(`${ environment.apiUrl }/role/` + slug).pipe();
  }
}
