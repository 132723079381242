import { Component, OnInit } from '@angular/core';
import { ProfilService } from '../../service/profil.service';
import { ServiceService } from 'src/app/utilisateur/service/service.service';
import { SocieteService } from '../../service/societe.service';
import { UtilisateurService } from '../../service/utilisateur.service';
import { User } from '../../domaine/user';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  utilisateurs: User[];
  selectedUser: User;
  user: User;
  totalRecords: number ;
  loading: boolean;

  constructor(private utilisateurService: UtilisateurService) { }

  ngOnInit(): void {
    this.loading = false;
    this.selectedUser =  new User();
    this.utilisateurService.all().subscribe(utilisateurs => {
      this.utilisateurs = utilisateurs.users;
      this.totalRecords = utilisateurs.totalRecord;
      this.loading = false;
    });
  }

  lazyLoad(event: LazyLoadEvent){
    this.loading = true;
    setTimeout(() => {
      if (this.utilisateurs) {
        this.utilisateurService.all(event.first / event.rows + 1, event.rows).subscribe(users => {
        this.utilisateurs = users.users;
        this.totalRecords = users.totalRecord;
        this.loading = false;
      });
     }
    }, 100);
  }

  doubleClickFunction(){
    console.log(this.selectedUser);
  }

}
