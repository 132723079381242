import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AutorisationService } from 'src/app/utilisateur/service/autorisation.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Autorisation } from 'src/app/utilisateur/domaine/autorisation';
import { Profil } from 'src/app/utilisateur/domaine/profil';
import { ProfilService } from 'src/app/utilisateur/service/profil.service';

@Component({
  selector: 'app-edit-profil',
  templateUrl: './edit-profil.component.html',
  styleUrls: ['./edit-profil.component.scss']
})
export class EditProfilComponent implements OnInit {

  roles: Autorisation[];
  profil: Profil;
  selectedAutorisations: Autorisation[];
  userForm: FormGroup;

  constructor(  private autorisationService: AutorisationService ,
                private  profilService: ProfilService,
                private route: ActivatedRoute ,
                private router: Router) { }

  ngOnInit(): void {
    this.profil = new Profil();
    const slug = this.route.snapshot.paramMap.get('slug');

    const roles = this.autorisationService.all();
    const profil = this.profilService.find(slug);

    this.userForm = new FormGroup({
      ctrName : new FormControl('', Validators.required),
      ctrDescription : new FormControl('', Validators.required),
      ctrActive : new FormControl(false , Validators.required )
    });

    forkJoin([roles, profil ]).subscribe(
      result => {

        this.roles = result[0];
        this.profil = result[1];
        this.selectedAutorisations = this.profil.roles;

        this.userForm.setValue({
          ctrName : this.profil.name,
          ctrDescription : this.profil.description,
          ctrActive : this.profil.active
        });

      });
  }


  edit(){
    this.profil.name = this.userForm.get('ctrName').value;
    this.profil.description = this.userForm.get('ctrDescription').value;
    this.profil.active = this.userForm.get('ctrActive').value;
    this.profil.roles = this.selectedAutorisations;


    this.profilService.update(this.profil.slug , this.profil).subscribe(
      data => {
        this.router.navigate(['/administration/utilisateur/profil']);
      },
      err => {
        alert(err.error.message);
      }
    );
  }


}
