import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Usage } from '../../domaine/usage';
import { UsageService } from '../../service/usage.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  usageForm: FormGroup;
  usageEdit: Usage;
  binary: boolean;

  categories: any[] = [{name: 'Oui'}, {name: 'Non'}];
 

  constructor(private usageService: UsageService,
              private route: ActivatedRoute ,
              private router: Router) {}

  ngOnInit(): void {
    this.usageEdit = new Usage();
    this.binary = false;
    const slug = this.route.snapshot.paramMap.get('slug');    

    this.usageForm = new FormGroup({
      ctrCode : new FormControl(null, Validators.required),
      ctrLibelle : new FormControl(null, Validators.required),
      ctrDescription : new FormControl(null, Validators.required),
      ctrIsActif : new FormControl({name:'Non'}, Validators.required),
      
    });

    this.usageService.find(slug).subscribe(
      data=>{
        this.usageEdit = data;        
        this.usageForm.setValue({
              ctrCode: this.usageEdit.code,
              ctrLibelle: this.usageEdit.libelle,
              ctrDescription: this.usageEdit.description,
             ctrIsActif: {name:(this.usageEdit.actif)? 'Oui': 'Non'},
              
        });

      },
      err =>{
        this.handleError(err);
      }
    );

    


  }

  update(){
    this.usageEdit.code = this.usageForm.get('ctrCode').value;
    this.usageEdit.libelle = this.usageForm.get('ctrLibelle').value;
    this.usageEdit.description = this.usageForm.get('ctrDescription').value;
    this.usageEdit.actif = (this.usageForm.get('ctrIsActif').value.name == 'Oui')? true: false;   

    this.usageService.update(this.usageEdit , this.usageEdit.slug).subscribe(
      data => {
        this.router.navigate(['/numerotation/administration/usage']);
      },
      err => {
        alert(err.error.message);
      }
    );
  }

  delete(){
    /*this.usageService.delete(this.usageEdit.slug).subscribe(
      data => {
        this.router.navigate(['/administration/utilisateur/user']);
      },
      err => {
        alert(err.error.Message);
      }
    );*/
  }


  handleError(err){
      alert(err.error.message);
      switch (err.status){
        
        case 404 : {
          alert(err.error.message);
          this.router.navigateByUrl('/');
          break;
        }
        
      }
    }

}