import { environment } from './../../../environments/environment';
import { Societes } from './../domaine/societes';
import { Societe } from 'src/app/utilisateur/domaine/societe';
import { Service } from './../domaine/service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocieteService {

  constructor(private http: HttpClient) { }

  all(): Observable<Societe[]>{
      return this.http.get<Societe[]>(`${ environment.apiUrl }/societe/`).pipe(
      map(societes => societes)
    );
  }


  add(societe: Societe): Observable<Societe>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Societe>(`${ environment.apiUrl }/societe/`, societe, options).pipe();
  }

  update(societe: Societe): Observable<Societe>{
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<Societe>(`${ environment.apiUrl }/societe/` + societe.id, societe, options).pipe();
  }

  delete(id: number): Observable<Societe>{
    return this.http.delete<Societe>(`${ environment.apiUrl }/societe/` + id).pipe();
  }
}
