import { style } from '@angular/animations';
import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer" style="text-align: center">
            <span class="footer-text-center">
                <!--img src="assets/layout/images/logo-dark.png" /-->
            </span>
            <span class="footer-text-center">
           <i class="fa fa-copyright" aria-hidden="true">  2022 Copyright Equipe FMSAR</i>
            </span>
        </div>
    `
})
export class AppFooterComponent {

}
